<template>
  <div class="cemetery-details-page">
    <div class="not-fount readable_content" v-if="cemeteryNotFound">
      <h5>Cemetery Not Found!</h5>
      <p>Sorry, we couldn't find the page at this address.</p>
    </div>
    <div class="limited_content" v-else-if="!cemeteryState || cemeteryLoadingState">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="limited_content details-content" v-else>
      <div class="desktop-photos" v-if="cemeterySource && cemeterySource.cover">
        <card-with-ribbon text="View images" @click.native="goToViewer">
          <img class="preview-image" :src="cemeterySource.cover.url" />
        </card-with-ribbon>
      </div>

      <div class="details">
        <div class="header">
          <h4>{{ cemeteryState.name }}</h4>
          <hr />
        </div>

        <div class="mobile-photos" v-if="cemeterySource && cemeterySource.cover">
          <card-with-ribbon text="View images" @click.native="goToViewer">
            <img class="preview-image" :src="cemeterySource.cover.url" />
          </card-with-ribbon>
        </div>

        <div class="content-container sub-section">
          <div v-html="cemeteryState.desc" v-prerender-if="'desc'" v-if="cemeteryState.desc"></div>
        </div>
        <div v-if="cemeteryState.website" v-prerender-if="'website'">
          <strong>Website:</strong> <a :href="cemeteryState.website" target="_blank">{{ cemeteryState.website }}</a>
        </div>
        <div v-if="cemeteryState.associations.length">
          <strong>Administered by:</strong> {{ cemeteryState.associations[0].name }}
        </div>
        <div v-if="cemeterySource && cemeterySource.images_count">
          <strong>Tombstone Images available:</strong> {{ cemeterySource.images_count }}
        </div>
        <div><strong>Total persons indexed at this cemetery:</strong> {{ cemeteryState.burial_records_count }}</div>

        <section-search-records
          v-if="cemeteryState.burial_records_count"
          :cemetery-name="cemeteryState.name"
          @submit="searchBurialRecords"
        ></section-search-records>
        <router-link v-if="cemeteryState.burial_records_count" class="sub-section browse-all" :to="searchAllRoute"
          >Browse all burial records in this cemetery</router-link
        >

        <google-map-location-point
          v-if="cemeteryState.location.lng || cemeteryState.location.lat"
          v-prerender-if="'location.lng or location.lat'"
          :lng="cemeteryState.location.lng"
          :lat="cemeteryState.location.lat"
          class="section"
        ></google-map-location-point>
        <metadata-table class="place-address">
          <template v-slot:rows>
            <detail-item
              v-prerender-for="{listName: 'place_details', valueName: 'place'}"
              :label="place.level_name"
              v-for="place in cemeteryState.place_details"
              :key="place.id"
            >
              <router-link :to="getPlaceRoute(place.id, place.pinyin)" rel="nofollow">
                {{ place.pinyin }}
              </router-link>
            </detail-item>
            <detail-item label="Address">{{ cemeteryState.address }}</detail-item>
            <detail-item label="Surnames" v-if="cemeterySource && cemeterySource.clans && cemeterySource.clans.length">
              <router-link
                :to="getSurnameRoute(name.name_hant || name.name_ch)"
                class="name"
                v-for="name in cemeterySource.clans"
                :key="name.name_ch"
                rel="nofollow"
                >{{ name.name_hant || name.name_ch }}</router-link
              >
            </detail-item>
            <detail-item
              label="Ancestral Place"
              v-if="cemeterySource.ancestral_places && cemeterySource.ancestral_places.length"
            >
              <router-link
                v-for="place in cemeterySource.ancestral_places"
                :key="place.id"
                :to="getPlaceRoute(place.id, place.pinyin)"
                class="name"
                rel="nofollow"
                >{{ place.pinyin }}</router-link
              >
            </detail-item>
          </template>
        </metadata-table>
        <p class="supplemental" v-if="cemeterySource.data_provider" v-prerender-if="'data_provider'">
          Source made available courtesy of
          <a :href="cemeterySource.data_provider_url" target="_blank" rel="nofollow">
            {{ cemeterySource.data_provider }}
          </a>
        </p>
        <div class="section" v-if="relatedSources.length" v-prerender-if="'related_sources|length'">
          <h5>Sources Associated with this Cemetery</h5>
          <ul>
            <li
              v-for="source in relatedSources"
              v-prerender-for="{listName: 'related_sources', valueName: 'related_source'}"
            >
              <router-link :to="{name: 'source-detail', params: {sourceId: source.id}}" rel="nofollow">
                {{ getSourceTitleDisplay(source) }}
              </router-link>
              <span> ({{ source.type_display }})</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardWithRibbon from '@common/elements/layouts/CardWithRibbon';
import MetadataTable from '@common/elements/layouts/MetadataTable';
import detailItem from '@common/elements/layouts/detailsItem';
import GoogleMapLocationPoint from '@common/elements/maps/GoogleMapLocationPoint';
import SectionSearchRecords from '@common/pages/cemetery/SectionSearchRecords';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import cemeteryData from './prerender/cemeteryData';

export default {
  metaInfo() {
    if (!this.cemeteryState) {
      return {};
    }
    const description = `The ${this.cemeteryState.name} is located in ${this.cemeteryState.place_full_name}. Search ${this.cemeteryState.burial_records_count} burial records from the ${this.cemeteryState.name}.`;
    const imageMeta = this.cemeteryState.meta_image_url
      ? [
          {
            vmid: 'og:image:secure_url',
            property: 'og:image:secure_url',
            content: this.cemeteryState.meta_image_url,
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: this.cemeteryState.meta_image_url,
          },
          {
            vmid: 'image',
            name: 'image',
            property: 'image',
            content: this.cemeteryState.meta_image_url,
          },
          {
            vmid: 'twitter:image',
            name: 'twitter:image',
            property: 'twitter:image',
            content: this.cemeteryState.meta_image_url,
          },
        ]
      : [];
    return {
      title: `The ${this.cemeteryState.name}`,
      meta: [
        {vmid: 'description', name: 'description', content: description},
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.cemeteryState.name,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        ...imageMeta,
      ],
    };
  },
  created() {
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setCemeteryState', cemeteryData);
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setCemeteryState', window.__PRERENDERED_STATE__);
      window.__PRERENDERED_STATE__ = null;
      return;
    }
    this.$store.dispatch('fetchCemeteryDetailsAction', this.$route.params.id).catch(() => {
      this.cemeteryNotFound = true;
    });
  },
  data() {
    return {
      isTemplate: false,
      cemeteryNotFound: false,
    };
  },
  computed: {
    ...mapGetters(['cemeteryState', 'cemeteryLoadingState']),
    cemeterySource() {
      return this.cemeteryState.cemetery_source || {};
    },
    relatedSources() {
      return this.cemeteryState.related_sources;
    },
    searchAllRoute() {
      return {name: 'search-all-records', query: {cemetery_id: this.$route.params.id, tab: TAB_ID_INDEXED_RECORDS}};
    },
  },
  methods: {
    searchBurialRecords({firstName = '', lastName = ''}) {
      let query = {
        first_name: firstName,
        surname: lastName,
        cemetery_id: this.$route.params.id,
        tab: TAB_ID_INDEXED_RECORDS,
      };
      return this.$router.push({name: 'search-all-records', query});
    },
    getSurnameRoute(word) {
      return {name: 'search-tool-surname-detail', query: {word}};
    },
    getPlaceRoute(id, name) {
      return getPlaceDetailRoute(id, name);
    },
    goToViewer() {
      const sourceId = this.cemeterySource.id;
      const image = this.cemeterySource.cover;
      this.$router.push({name: 'source-viewer', params: {sourceId}, query: {page: image.priority, page_id: image.id}});
    },
    getSourceTitleDisplay(source) {
      const {publication_year, time_period_start, time_period_end} = source;
      const years =
        publication_year ||
        (time_period_start || time_period_end ? `${time_period_start || '...'}-${time_period_end || '...'}` : '');
      const title = source.title_en || source.title_ch;
      return years ? `${title}, ${years}` : title;
    },
  },
  components: {CardWithRibbon, SectionSearchRecords, detailItem, GoogleMapLocationPoint, MetadataTable},
};
</script>

<style lang="scss" scoped>
.cemetery-details-page {
  min-height: 500px;

  .details-content {
    display: flex;
    flex-direction: row;

    .desktop-photos {
      margin-right: 48px;
      width: 40%;
      min-width: 280px;
      flex-shrink: 0;
      position: relative;

      .card-with-ribbon {
        min-width: 100%;
      }
    }

    .mobile-photos {
      display: none;
    }

    .details {
      flex-grow: 1;
    }
  }

  .content-container {
    text-align: justify;
  }
}

.sub-section {
  margin-top: 18px;
  display: block;
}

.section {
  margin-top: 50px;
}

a.sub-section.browse-all {
  display: inline-block;
}

.place-address {
  margin-top: 50px;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .cemetery-details-page {
    .details-content {
      flex-direction: column;

      .desktop-photos {
        display: none;
      }

      .mobile-photos {
        display: block;
        margin: 30px 0;
        .card-with-ribbon {
          margin: auto;
        }
      }
    }
  }
}
</style>
